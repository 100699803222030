<template>
  <div>
    <div class="columns mx-0">
      <div class="column">
        <div class="level p-0 m-0">
          <div class="level-left">
            <div class="level-item">Qytetet</div>
            <input class="input" style="margin: 5px; width: 300px" type="text" placeholder="Kerko..."
              v-model="global_search_filter" @input="onFilterTextBoxChanged" />
          </div>
          <div class="level-right">
            <div class="level">
              <a @click.prevent="create_city">
                <i class="fa fa-plus"></i>
              </a>
            </div>
          </div>
        </div>
        <ag-grid-vue style="width: 100%; height: 200px" class="ag-theme-balham" :columnDefs="columnDefs"
          :rowData="rowData.value" :rowSelection="rowSelection" :context="context" :localeText="localeText"
          :animateRows="true" @grid-ready="onGridReady">
        </ag-grid-vue>
      </div>
      <div class="column is-5" v-if="form_edit || form_create">
        <DaForm v-if="form_create" @submitted="submitCity" :form="form_create" @close="form_create = null" :closes_form="true"></DaForm>
        <DaForm v-if="form_edit" @submitted="submitCity" :form="form_edit" @close="form_edit = null" :closes_form="true"></DaForm>
      </div>
    </div>
  </div>
</template>

<script>
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { AgGridVue } from "ag-grid-vue3";
import DaForm from "@/components/daform/index.vue";
import ButtonsCell from "@/components/buttonscell/ButtonsCell.vue";
import createCity from "../forms/cities/create";
import editCity from "../forms/cities/edit";
import socket from '@/socket.js';
import locale from "@/components/ag-grid/locale/al/al.js"
import CitiesServices from "@/services/City";
import { reactive, inject, onUnmounted } from 'vue';
export default {
  components: {
    AgGridVue,
    DaForm,
  },
  setup() {
    let rowData = reactive([])
    const toast = inject('toast')
    socket.connect()
    socket.on('welcome', data => {
      console.log('welcome', data)
    })
    socket.on("propagate", data => {
      if(data.action == "create") {
        var a = rowData.value
        a.push(data.data)
        rowData.value = [...a]
        toast.success('Qyteti u krijua nga ' + data.created_by.name)
      }
      if(data.action == "edit") {
        rowData.value = [...rowData.value.map(e => {
          if(e.id == data.data.id) {
            return data.data
          }
          return e
        })]
        toast.success('Qyteti u modifikua nga ' + data.created_by.name)
      }
    })
    onUnmounted(() => {
      socket.off('welcome')
      socket.off('propagate')
      socket.disconnect()
    })
    return {
      rowData
    }
  },
  beforeMount() {
    this.context = { componentParent: this };
  },
  data() {
    return {
      global_search_filter: "",
      context: null,
      selectedCity: {},
      cities: [],
      form_create: null,
      form_edit: null,
      columnDefs: [
        {
          headerName: "Emri",
          field: "name",
          filter: true,
          floatingFilter: true,
          sortable: true,
        },
        {
          headerName: "Action",
          cellRenderer: ButtonsCell,
          cellRendererParams: this.context,
        }
      ],
      gridApi: null,
      defaultColDef: {
        flex: 1,
        minWidth: 200,
      },
      rowSelection: "single",
      localeText: null,
    };
  },
  methods: {
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.global_search_filter)
    },
    async open(mode, city) {
      if (mode == "1") {
        this.form_create = null
        this.form_edit = null
        if (city) {
          this.form_edit = { loading: true }
          this.form_edit = await editCity(city)
          this.selectedCity = city
          this.gridApi.sizeColumnsToFit();
        }
      }
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.gridApi.sizeColumnsToFit();
      this.gridColumnApi = params.columnApi
    },
    async create_city() {
      this.form_edit = null
      this.form_create = { loading: true }
      this.form_create = await createCity()
      this.gridApi.sizeColumnsToFit();
    },
    async submitCity(c) {
      var notify_text = ""
      if (this.form_create != null) {
        let cities = await CitiesServices.createCity(c)
        var a = this.rowData.value
        a.push(cities)
        this.rowData.value = [...a]
        socket.emit("propagate", { action: "create", data: cities })
        notify_text = "Qyteti u krijua me sukses!"
        this.form_create = null
        this.$root.selected_one = {}
      }
      else {
        c.guid = this.selectedCity.guid
        let city = await CitiesServices.editCity(c)
        this.rowData.value = [...this.rowData.value.map(ci => {
          if (ci.guid == city.guid) ci = { ...city }
          return ci
        })]
        socket.emit("propagate", { action: "edit", data: city })
        notify_text = "Qyteti u modifikua me sukses!"
        this.form_edit = null
        this.$root.selected_one = {}
      }
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 100);
      this.$toast.success(notify_text)
    }
  },
  async created() {
    this.localeText = locale.locale_al()
    this.rowData.value = await CitiesServices.getCities()
    setTimeout(() => {
      this.gridApi.sizeColumnsToFit()
    })
  },
  onBeforeDestroy() {
    socket.disconnect();
  },
};
</script>

<style scoped>

</style>
